
import { JoinModule } from '@/store/join';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { parseConcatNumber } from '@/filters';
import { Form } from 'element-ui';
import { VueDaumPostcode } from 'vue-daum-postcode';
import { UserModule } from '@/store/modules/user';
import { getJwtInfo } from '@/utils/cookies';

@Component({
  name: 'joinStep3',
  components: {
    VueDaumPostcode,
  },
})

export default class extends Vue {
  get form() {
    JoinModule.form.actualName = UserModule.infoName;
    JoinModule.form.concatNumber = UserModule.infoTel;
    return JoinModule.form;
  }

  mounted() {
    this.checkSocialUser();
  }

  private rules: any = {
    actualName: [
      { required: 'true', message: '이름를 입력하세요', trigger: ['blur', 'change'] },
    ],
    concatNumber: [{
        required: 'true', message: '연락처를 입력하세요', trigger: ['blur', 'change'], min: 13,
    }],
    address: [
      { required: 'true', message: '주소를 입력하세요', trigger: ['blur', 'change'] },
    ],
    addressDetail: [
      { required: 'true', message: '상세 주소를 입력하세요', trigger: ['blur', 'change'] },
    ],
  };

  private addressVisible = false;

  private filterConcatNumber() {
    this.form.concatNumber = parseConcatNumber(this.form.concatNumber);
  }

  private handleJoin() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        this.form.step = 3;
        this.$router.push({ name: 'Info' }).catch(() => {
          // console.log('');
        });
      }
    });
  }

  private handleVisibleAddress() {
    this.addressVisible = !this.addressVisible;
  }

  private handleComplete(result: any) {
    this.form.postCode = result.zonecode;
    this.form.address = result.address;
    this.handleVisibleAddress();
  }

  private checkSocialUser() {
    /* eslint-disable */
		const token = UserModule.token;
		if (token) {
			const status = getJwtInfo('status');
			if (status) {
				this.$router.push({ name: 'Home' });
			} else {
				if (this.form.step !== 2) {
					this.$router.push({ name: 'Agree' });
				}
			}
		} else {
			this.$router.push({ name: 'Login' });
		}
		/* eslint-enable */
  }
}
